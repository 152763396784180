import React from 'react';
import './Zonezapp.css';
import Contact from '../contact/Contact';
import { Link } from 'react-router-dom';



const ContactUs = () => {
    return(
        <section id='contactUs' className='contactus-wrapper'>
            <Contact />
            {/* <Link to="/" style={{ marginTop: '20px' }} >View our homepage</Link> */}
        </section>
    );
};

export default ContactUs;