import React from 'react';
import './Hero.css';
import 'animate.css';
import IconButton from '@mui/material/IconButton';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FileDownload from '@mui/icons-material/FileDownload';
import HeroVideoCover from '../../assets/video/hero-video-2.mp4';
import AppstoreDark from '../../assets/svgs/appstore-dark.svg';
import AppstoreLight from '../../assets/svgs/appstore-light.svg';
import GoogleplayDark from '../../assets/svgs/googleplay-dark.svg';
import GoogleplayLight from '../../assets/svgs/googleplay-light.svg';
import Typewriter from 'typewriter-effect';



function Hero( { handleOpenModal } ) {
    return (
        <section id="home" className='hero-wrapper'>
            <div className="container col-xxl-8 px-4 py-5">
                <div className="row flex-lg-row-reverse align-items-center g-5 py-1">
                    <div className="col-12 col-md-10 col-lg-6 hero-image-container animate__animated animate__fadeInRight">
                        <video autoPlay muted loop className="hero-video-cover animate__animated animate__fadeInTopRight animate__delay-2s">
                            <source src={HeroVideoCover} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="col-lg-6 text-center text-lg-start">
                        <h1 className="display-5 fw-bold lh-1 mb-3">
                            <Typewriter
                                options={{
                                    strings: [
                                        '<span class="o-hue">Con</span>nec<span class="o-hue">ting</span> Com<span class="o-hue">mu</span>ni<span class="o-hue">ties</span> in <span class="o-hue">Real</span>-Time<span class="o-hue">.</span>',
                                        '<span class="o-hue">Join</span> ZoneZapp for <span class="o-hue">Au</span>then<span class="o-hue">tic</span> News<span class="o-hue">.</span>',
                                        '<span class="o-hue">Be</span>cause the <span class="o-hue">truth</span> is <span class="o-hue">worth</span> it<span class="o-hue">.</span>'
                                    ],
                                    autoStart: true,
                                    loop: true,
                                    delay: 75,
                                    deleteSpeed: 30,
                                    // Enabling HTML interpretation
                                    wrapperClassName: 'typewriter-text',
                                    cursorClassName: 'typewriter-cursor',
                                    html: true,  // This allows HTML inside the strings
                                }}
                            />
                        </h1>
                        <p className="lead animate__animated animate__fadeInLeft">
                            Join ZoneZapp today and be part of a movement towards unbiased, authentic news.
                            Let's build a community where every voice matters, and every story counts.
                            Download ZoneZapp now and experience the difference. Because the truth is worth it.
                        </p>
                        <div className="d-grid gap-2 d-md-flex justify-content-center justify-content-md-start animate__animated animate__fadeInRight">
                            <a
                                onClick={() => handleOpenModal()}
                                href="#"
                                className="btn btn-dark btn-lg px-3 py-2 mb-3">
                                Join Now! <FileDownload />
                            </a>
                        </div>
                        <div className="btn-link-container gap-2 d-md-flex align-items-center justify-content-center justify-content-md-start mt-3 animate__animated animate__fadeInLeft animate__delay-2s">
                            <IconButton
                                href="#about"
                                className="expand-icon-btn"
                                aria-label="Scroll for more"
                                style={{ backgroundColor: '#fff', borderRadius: '50%', padding: '12px' }}
                            >
                                <ExpandMore className="expandMore-icon" />
                            </IconButton>
                            <span className="fs-sm">Discover more</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;