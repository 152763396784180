import React from 'react';
import './PrivacyPolicy.css';
import AboutIMG_One from '../../assets/backgrounds/privacy-policy.png';



const PrivacyPolicy = () => {
    return (
        <section id="privacy-policy" className="privacy-policy-wrapper">

            <div className="container">
                <div className="row featurette flex-wrapper">
                    <div className="col-md-7 order-md-2">
                        <h2 className="featurette-heading animate__animated animate__zoomIn">Privacy <span className="text-highlight">Policy</span>.</h2>
                        <p className="text animate__animated animate__fadeInRight">
                            Your privacy is important to us. This privacy policy explains how we collect, use, and protect your personal information when you use ZoneZapp.
                            We collect information to provide better services to our users. The information we collect includes personal details such as your name, email address, and location. We do not share your personal information with third parties without your consent.
                            We implement a variety of security measures to maintain the safety of your personal information. You can review and update your personal information by accessing your account settings at any time.
                        </p>
                    </div>

                    <div className="col-md-5 order-md-1">
                        <div className="privacy-policy-image-container">
                            <img className="p-policy-image-one animate__animated animate__fadeInLeft" src={AboutIMG_One} alt="p-policy-sketch" />
                        </div>
                    </div>

                </div>
            </div>

        </section>
    );
};

export default PrivacyPolicy;
