import React, { useState } from 'react';
import './Stepper.css';
import AppShowcaseOne from '../../assets/images/steper-1.jpeg';
import AppShowcaseTwo from '../../assets/images/steper-2.jpeg';
import AppShowcaseThree from '../../assets/images/steper-3.jpg';
import AppShowcaseFour from '../../assets/images/steper-4.jpeg';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const steps = [
    {
        title: "Step 1.",
        description: "The user signs up by completing the input fields.",
        image: AppShowcaseOne,
    },
    {
        title: "Step 2.",
        description: "The user receives an OTP via email to be verified.",
        image: AppShowcaseTwo,
    },
    {
        title: "Step 3.",
        description: "Choose a location and click Request to start a new conversation.",
        image: AppShowcaseThree,
    },
    {
        title: "Step 4.",
        description: "Connect and have interesting conversations with other users.",
        image: AppShowcaseFour,
    }
];

const Stepper = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,  // Enable automatic sliding
        autoplaySpeed: 3000,  // 3 seconds
        beforeChange: (current, next) => setCurrentSlide(next), // Track slide change
    };

    // Calculate progress percentage based on the current slide
    const progressPercentage = ((currentSlide + 1) / steps.length) * 100;

    return (
        <section id="stepper" className="stepper-wrapper">
            <div className="carousel-container">
                <h1 className="heading animate__animated animate__fadeInDown">
                    <span className='color'>How Does</span> ZoneZapp <span className='color'>Work?</span>
                </h1>
                <div className="mobile-frame animate__animated animate__rollIn">
                    <Slider {...settings}>
                        {steps.map((step, index) => (
                            <div key={index} className="step-slide">
                                <img src={step.image} alt={`Step ${index + 1}`} className="step-image animate__animated animate__rollIn animate__delay-2s" />
                                <div className="step-info">
                                    <h2 className='animate__animated animate__fadeInLeft animate__delay-3s'>{step.title}</h2>
                                    <p className='text-center animate__animated animate__fadeInRight animate__delay-3s'>{step.description}</p>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
            {/* Move Progress Bar to the footer of stepper-wrapper */}
            <div className="progress-bar-wrapper">
                <div className="progress-bar" style={{ width: `${progressPercentage}%` }}></div>
            </div>
        </section>
    );
};

export default Stepper;
