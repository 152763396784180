import React, { useEffect, useState } from 'react';
import './StickyNavbar.css';
import logo from '../../logo.png';
import Button from '@mui/material/Button';
import { Segment } from '@mui/icons-material';




function StickyNavbar( { handleOpenModal } ) {
    const [showNavbar, setShowNavbar] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);
    const [activeLink, setActiveLink] = useState('#home'); // Track the active link

    const controlNavbar = () => {
        if (window.scrollY === 0) {
            setShowNavbar(true);  // Always show the navbar at the top of the page
        } else if (window.scrollY > lastScrollY) {
            // Hide the navbar when scrolling down
            setShowNavbar(false);
        } else if (window.scrollY < lastScrollY) {
            // Show the navbar when scrolling up
            setShowNavbar(true);
        }
        setLastScrollY(window.scrollY);  // Update lastScrollY to current scroll position
    };

    const handleNavLinkClick = (id) => {
        setActiveLink(id); // Update the active link state
    };

    useEffect(() => {
        window.addEventListener('scroll', controlNavbar);
        return () => {
            window.removeEventListener('scroll', controlNavbar);  // Cleanup event listener on component unmount
        };
    }, [lastScrollY]);

    return (
        <>
            <nav className={`navbar navbar-expand-lg fixed-top ${showNavbar ? 'navbar-show' : 'navbar-hide'}`}>
                <div className="container-fluid">
                    <a className="navbar-brand logo-btn" href="#">
                        <img src={logo} alt="ZoneZapp Logo" height="70" width="75" />
                        <span className='brand'>ZoneZapp</span>
                    </a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarResponsive"
                        aria-controls="navbarResponsive"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <Segment className="toggler-icon" />
                    </button>
                    <div className="collapse navbar-collapse justify-content-center" id="navbarResponsive">
                        <ul className="navbar-nav mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a
                                    className={`nav-link ${activeLink === '#home' ? 'active' : ''}`}
                                    href="#home"
                                    onClick={() => handleNavLinkClick('#home')}
                                >
                                    Home
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className={`nav-link ${activeLink === '#features' ? 'active' : ''}`}
                                    href="#features"
                                    onClick={() => handleNavLinkClick('#features')}
                                >
                                    Features
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className={`nav-link ${activeLink === '#about' ? 'active' : ''}`}
                                    href="#about"
                                    onClick={() => handleNavLinkClick('#about')}
                                >
                                    About
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className={`nav-link ${activeLink === '#contact' ? 'active' : ''}`}
                                    href="#contact"
                                    onClick={() => handleNavLinkClick('#contact')}
                                >
                                    Contact
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="d-flex ms-auto">
                        <Button
                            className='download-btn'
                            variant="outlined"
                            color="secondary"
                            sx={{ marginRight: '16px' }}
                            onClick={() => handleOpenModal()}
                        >
                            Get In Touch!
                        </Button>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default StickyNavbar;
