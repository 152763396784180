import React, { useState } from 'react';
import './Contact.css';
import { Call, Email, Public } from '@mui/icons-material';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    subject: '',
    message: ''
  });
  const [responseMessage, setResponseMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);  // Show loader
  
    try {
      const response = await fetch('https://alpha.zonezapp.com/api/contact-us', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
  
      const result = await response.json();
      if (response.ok) {
        setResponseMessage('Your message has been sent successfully!');
        setErrorMessage('');
        setFormData({ name: '', phone: '', email: '', subject: '', message: '' }); // Reset form fields
  
        // Set timeout to hide the success message after 5 seconds
        setTimeout(() => {
          setResponseMessage('');
        }, 5000);
        
      } else {
        setErrorMessage(result.error || 'Failed to send message.');
        setResponseMessage('');
  
        // Set timeout to hide the error message after 5 seconds
        setTimeout(() => {
          setErrorMessage('');
        }, 5000);
      }
    } catch (error) {
      setErrorMessage('An error occurred while sending your message.');
      setResponseMessage('');
  
      // Set timeout to hide the error message after 5 seconds
      setTimeout(() => {
        setErrorMessage('');
      }, 5000);
    } finally {
      setLoading(false);  // Hide loader after response
    }
  };
  

  return (
    <section id="contact-us" className="contact-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-xs-12">
            <div className="left-text-content">
              <div className="section-heading">
                <h6 className="text-start">Contact Us</h6>
                <h2 className="text-start">Feel free to keep in touch with us!</h2>
              </div>
              <ul className="contact-info">
                <li>
                  <div className="col d-flex align-items-center">
                    <div className="icon-square glassmorphism flex-shrink-0 me-3">
                      <Email className="bi" width="1em" height="1em" />
                    </div>
                    <div>
                      <a href="mailto:customerservice@zonezapp.com" className="mail-link">
                        customerservice@zonezapp.com
                      </a>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="col d-flex align-items-center">
                    <div className="icon-square glassmorphism flex-shrink-0 me-3">
                      <Public className="bi" width="1em" height="1em" />
                    </div>
                    <div>
                      <a href="https://online.zonezapp.com/" className="company-link">
                        online.zonezapp.com
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-8 col-md-8 col-xs-12">
            <div className="contact-form">
              <form id="contact" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <fieldset>
                      <input 
                        name="name" 
                        type="text" 
                        id="name" 
                        placeholder="Your Name *" 
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </fieldset>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <fieldset>
                      <input 
                        name="phone" 
                        type="text" 
                        id="phone" 
                        placeholder="Your Phone" 
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                    </fieldset>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <fieldset>
                      <input 
                        name="email" 
                        type="email" 
                        id="email" 
                        placeholder="Your Email *" 
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </fieldset>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <fieldset>
                      <input 
                        name="subject" 
                        type="text" 
                        id="subject" 
                        placeholder="Subject" 
                        value={formData.subject}
                        onChange={handleChange}
                      />
                    </fieldset>
                  </div>
                  <div className="col-lg-12">
                    <fieldset>
                      <textarea 
                        name="message" 
                        rows="6" 
                        id="message" 
                        placeholder="Message" 
                        value={formData.message}
                        onChange={handleChange}
                        required
                      ></textarea>
                    </fieldset>
                  </div>
                  <div className="col-lg-12">
                    <fieldset>
                      <button 
                        type="submit" 
                        id="form-submit" 
                        className="main-button-icon" 
                        disabled={loading}
                      >
                        {loading ? (
                          <div className="loader"></div>
                        ) : (
                          <>
                            Send Message Now <i className="fa fa-arrow-right"></i>
                          </>
                        )}
                      </button>
                    </fieldset>
                  </div>
                </div>
              </form>
              {responseMessage && <div className="alert alert-success">{responseMessage}</div>}
              {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;