import React from 'react';
import './About.css';
import AboutIMG_One from '../../assets/mockups/about-1.png';
import AboutIMG_Two from '../../assets/backgrounds/about-2.png';


const About = () => (
    <section id="about" className='about-wrapper'>

        <div className="container">
            <div className='featurette-divider'></div>
            <div className="row featurette">
                <div className="col-md-7 order-md-2">
                    <h2 className="featurette-heading animate__animated animate__fadeInDown animate__delay-3s">
                        About <span className="text-highlight">ZoneZapp</span>.
                    </h2>
                    <p className="text animate__animated animate__fadeInDown animate__delay-3s">
                        In today's world, it's easy to feel overwhelmed by the constant stream of news, often filtered through biases and agendas.
                        At ZoneZapp, we believe in a different approach—a community-driven platform where real people share real stories,
                        giving you an authentic and unfiltered view of what's happening around you. Imagine a place where your neighbors,
                        friends, and community members come together to share real-time updates and genuine experiences.
                        A place where you can trust the information because it comes directly from those living it,
                        without the spin of traditional media channels.
                    </p>
                    <br />
                    <p className="text animate__animated animate__fadeInDown animate__delay-3s">
                        ZoneZapp is a location-based sharing platform enabling users to request and share real-time information within a specified radius.
                    </p>
                </div>
                
                <div className="col-md-5 order-md-1 animate__animated animate__fadeIn animate__delay-3s">
                    <div className="about-image-container">
                        <img className="about-image-one" src={AboutIMG_One} alt="about-sketch" />
                        <img className="about-image-two" src={AboutIMG_Two} alt="about-sketch" />
                    </div>
                </div>

            </div>
            <div className='featurette-divider'></div>
        </div>

    </section>
);

export default About;
