import React from 'react';
import './Mission.css';



const Mission = () => (
    <section id="mission" className='mission-wrapper'>
        <div className="cover-container">
            <div className="overlay"></div>
            <div className="content">
                <h2 className="features-heading pb-2 text-center mission-heading animate__animated animate__fadeInDown">Our Mission</h2>

                <div className="col-lg-6 mx-auto">
                    <p className="mission-text mb-4 animate__animated animate__fadeInUp">
                        Our mission is to foster community engagement and provide users with authentic, localized content that are user-generated.
                        ZoneZapp is dedicated to connecting communities through real-time information sharing. We aim to empower users with the latest updates about their local area, fostering engagement and a sense of community.
                        Founded in Fredericton, New Brunswick, our team is committed to providing a reliable platform that users can trust. We believe in transparency, user privacy, and creating a positive impact in the communities we serve.
                    </p>
                </div>
            </div>
        </div>
    </section>
);

export default Mission;
