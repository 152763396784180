import React from 'react';
import './Zonezapp.css';
import { Link } from 'react-router-dom';
import '../parent-components/dialog-trigger/DialogTrigger.css';



const TermsAndConditions = () => {
    return (
        <section id="terms-and-conditions" className='landingPage-wrapper'>
            <div className='container terms-conditions-container'>
                <br />
                <h2 className='text-center'>Terms and Conditions</h2>
                <br />
                <div className='content-wrapper' style={{ maxHeight: '60vh', overflowY: 'auto', padding: '0 15px' }}>
                    <br />
                    <h4>1. Acceptance of Terms</h4>
                    <p>By accessing or using the ZoneZapp application ("App"), you agree to be bound by these Terms and Conditions ("Terms"). If you do not agree to all of these Terms, do not use this App.</p>

                    <h4>2. Privacy and Personal Information</h4>
                    <p>By using ZoneZapp, you agree to the collection, transfer, storage, and use of your personal information by the App (as set out in the Privacy Policy), including but not limited to your profile picture, profile name, which may be shared with other users within the ZoneZapp framework. We do not share your exact location with other users.</p>

                    <h4>3. User Conduct</h4>
                    <p>You agree not to use the App in any way that:</p>
                    <ul>
                        <li>Is unlawful, harmful, threatening, defamatory, infringing, harassing, or racially or ethnically offensive.</li>
                        <li>Facilitates illegal activity.</li>
                        <li>Depicts sexually explicit images.</li>
                        <li>Promotes unlawful violence.</li>
                        <li>Is discriminatory based on race, gender, color, religious belief, sexual orientation, disability, or any other illegal activity.</li>
                    </ul>

                    <h4>4. Content Ownership</h4>
                    <p>You retain all of your ownership rights in your content, but by submitting content to ZoneZapp, you grant a worldwide, non-exclusive, royalty-free license to use, reproduce, distribute, and display the content in connection with the App.</p>

                    <h4>5. Service Changes and Limitations</h4>
                    <p>The App and its features may change from time to time. ZoneZapp reserves the right to modify, suspend, or discontinue any part of the App at any time, with or without notice.</p>

                    <h4>6. Disclaimers</h4>
                    <p>ZoneZapp provides the App on an 'as is' and 'as available' basis without any warranties, expressed or implied, of merchantability, fitness for a particular purpose, or non-infringement.</p>

                    <h4>7. Liability Limitation</h4>
                    <p>To the maximum extent permitted by law, ZoneZapp shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues.</p>

                    <h4>8. Changes to Terms</h4>
                    <p>ZoneZapp reserves the right, at our sole discretion, to modify or replace these Terms at any time. By continuing to access or use our App after those revisions become effective, you agree to be bound by the revised terms.</p>

                    <h4>9. Contact Information</h4>
                    <p>If you have any questions about these Terms, please contact us at customerservice@zonezapp.com.</p>

                    <p style={{ marginTop: '20px' }}>User Acknowledgment: By creating an account and using ZoneZapp, I acknowledge that I have read, understood, and agreed to the above Terms and Conditions.</p>

                    <br />

                    <div className='d-flex justify-content-center'>
                        <Link to="/zonezapp" className="back-link">Go Back to Register</Link>
                    </div>
                </div>
                <br />
            </div>
        </section>
    );
};

export default TermsAndConditions;