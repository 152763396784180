import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';



const Footer = () => (

    <footer>
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-xs-12">
                    <div className="left-text-content">
                        <p className='copy-right-text'>ZoneZapp. &copy; 2024 All rights reserved.</p>
                    </div>
                </div>
                <div className="col-lg-6 col-xs-12">
                    <div className="right-text-content">
                        <ul className="social-icons">
                            <li><p>Follow Us</p></li>
                            <li><a href="https://www.linkedin.com/company/zonezapp-tech-ltd/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedinIn} /></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>

);

export default Footer;
