import React from 'react';
import About from '../../about/About';
import Footer from '../../footer/Footer';
import Contact from '../../contact/Contact';
import Mission from '../../mission/Mission';
import Stepper from '../../stepper/Stepper';
import Features from '../../features/Features';
import Marketing from '../../marketing/Marketing';
import Subscribe from '../../subscribe/Subscribe';
import BackToTopButton from '../../backTotop/BackTotop';
import DialogTrigger from '../dialog-trigger/DialogTrigger';
import PrivacyPolicy from '../../privacy-policy/PrivacyPolicy';

const SinglePager = () => (
    <div className='singlepager-wrapper'>

        {/* Contains both the StickyNavbar & Hero component */}
        <DialogTrigger />

        <About />
        <Features />
        <Marketing />
        <Mission />
        <PrivacyPolicy />
        <Stepper />
        <Subscribe />
        <Contact />
        <Footer />
        <BackToTopButton />

    </div>
);

export default SinglePager;