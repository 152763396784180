import React from 'react';
import './Marketing.css';
import Mic from '@mui/icons-material/Mic';
import Bolt from '@mui/icons-material/Bolt';
import Forum from '@mui/icons-material/Forum';
import Video from '../../assets/video/cover-video.mp4';
import MarketingIMG from '../../assets/backgrounds/marketing-bg-2.png';


const Marketing = () => (
    <section id="marketing" className='marketing-wrapper'>

        <div className="container">
            <div className="row featurette">
                <div className="col-md-7">
                    <h2 className="featurette-heading animate__animated animate__fadeIn animate__delay-2s">
                        Why <span className="text-highlight">ZoneZapp</span>?
                    </h2>
                    <p className="text animate__animated animate__fadeIn">
                        We understand the frustration of trying to sift through biased news to find the truth.
                        ZoneZapp is here to change that by putting the power of information back in your hands.
                        Together, we can create a space where transparency, honesty, and community spirit thrive.
                    </p>

                    <div className="row row-cols-1 g-4 py-5">
                        <div className="col d-flex justify-content-start animate__animated animate__fadeInLeft">
                            <div className="icon-square glassmorphism flex-shrink-0 me-3">
                                <Mic className="bi" width="1.25em" height="1.25em" />
                            </div>
                            <div className='start-content'>
                                <h4 className="mb-0">Authentic Voices</h4>
                                <p>Hear from real people in your community,<br/> sharing their honest perspectives.</p>
                            </div>
                        </div>
                        <div className="col d-flex justify-content-start animate__animated animate__fadeInRight">
                            <div className="icon-square glassmorphism flex-shrink-0 me-3">
                                <Bolt className="bi" width="1.25em" height="1.25em" />
                            </div>
                            <div className='start-content'>
                                <h4 className="mb-0">Instant Updates</h4>
                                <p>Get the latest news and events as they happen,<br/> from those who are right there.</p>
                            </div>
                        </div>
                        <div className="col d-flex justify-content-start animate__animated animate__fadeInLeft">
                            <div className="icon-square glassmorphism flex-shrink-0 me-3">
                                <Forum className="bi" width="1.25em" height="1.25em" />
                            </div>
                            <div className='start-content'>
                                <h4 className="mb-0">Community Connection</h4>
                                <p>Engage in meaningful discussions and build a stronger,<br/> more informed community.</p>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="col-md-5">
                    <div className="marketing-image-container animate__animated animate__fadeIn">
                        <video autoPlay muted loop className="marketing-image-one animate__animated animate__fadeInTopRight animate__delay-2s">
                            <source src={Video} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <img className="marketing-image-two" src={MarketingIMG} alt="marketing-sketch" />
                    </div>
                </div>
            </div>
        </div>

    </section>
);

export default Marketing;
