import React, { useState } from 'react';
import './DialogTrigger.css';
import GetInTouchForm from './GetInTouchForm';
import StickyNavbar from '../../sticky-navbar/StickyNavbar';
import Hero from '../../hero/Hero';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { Close } from '@mui/icons-material';
import ModelCover from '../../../assets/svgs/register.svg';
import logo from '../../../logo.png';

function DialogTrigger() {
    const [openModal, setOpenModal] = useState(false);
    const [showTerms, setShowTerms] = useState(false); // To toggle between form and terms

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setShowTerms(false); // Reset to form when the modal closes
    };

    const handleToggleContent = () => {
        setShowTerms(!showTerms); // Toggle between form and terms content
    };

    return (
        <>
            <StickyNavbar handleOpenModal={handleOpenModal} />
            <Hero handleOpenModal={handleOpenModal} />

            {/* Modal for the "Get it now!" button */}
            <Dialog
                fullScreen
                open={openModal}
                onClose={handleCloseModal}
                PaperProps={{
                    style: { color: 'white', backdropFilter: 'blur(10px)', backgroundColor: 'rgba(255, 255, 255, 0.2)' }
                }}
                disableScrollLock
            >
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={handleCloseModal}
                    aria-label="close"
                    style={{ position: 'absolute', top: '20px', right: '40px', background: 'white', color: 'rgba(0, 0, 0, 0.85)' }}
                >
                    <Close />
                </IconButton>
                <DialogContent>
                    <div className="modal-content-wrapper" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <div className="modal-content" style={{ opacity: 0.8, backgroundColor: 'white', border: '1px solid rgba(255, 255, 255, 0.3)', color: 'black', padding: '20px', borderRadius: '8px', maxWidth: 'fit-content', width: '75%', textAlign: 'center' }}>
                            {showTerms ? (
                                <div className='terms-conditions-container'>
                                    <h2>Terms and Conditions</h2>
                                    <div className='content-wrapper' style={{  maxHeight: '60vh', overflowY: 'auto', padding: '0 15px' }}>
                                        <h4>1. Acceptance of Terms</h4>
                                        <p>By accessing or using the ZoneZapp application ("App"), you agree to be bound by these Terms and Conditions ("Terms"). If you do not agree to all of these Terms, do not use this App.</p>

                                        <h4>2. Privacy and Personal Information</h4>
                                        <p>By using ZoneZapp, you agree to the collection, transfer, storage, and use of your personal information by the App (as set out in the Privacy Policy), including but not limited to your profile picture, profile name, which may be shared with other users within the ZoneZapp framework. We do not share your exact location with other users.</p>

                                        <h4>3. User Conduct</h4>
                                        <p>You agree not to use the App in any way that:</p>
                                        <ul>
                                            <li>Is unlawful, harmful, threatening, defamatory, infringing, harassing, or racially or ethnically offensive.</li>
                                            <li>Facilitates illegal activity.</li>
                                            <li>Depicts sexually explicit images.</li>
                                            <li>Promotes unlawful violence.</li>
                                            <li>Is discriminatory based on race, gender, color, religious belief, sexual orientation, disability, or any other illegal activity.</li>
                                        </ul>

                                        <h4>4. Content Ownership</h4>
                                        <p>You retain all of your ownership rights in your content, but by submitting content to ZoneZapp, you grant a worldwide, non-exclusive, royalty-free license to use, reproduce, distribute, and display the content in connection with the App.</p>

                                        <h4>5. Service Changes and Limitations</h4>
                                        <p>The App and its features may change from time to time. ZoneZapp reserves the right to modify, suspend, or discontinue any part of the App at any time, with or without notice.</p>

                                        <h4>6. Disclaimers</h4>
                                        <p>ZoneZapp provides the App on an 'as is' and 'as available' basis without any warranties, expressed or implied, of merchantability, fitness for a particular purpose, or non-infringement.</p>

                                        <h4>7. Liability Limitation</h4>
                                        <p>To the maximum extent permitted by law, ZoneZapp shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues.</p>

                                        <h4>8. Changes to Terms</h4>
                                        <p>ZoneZapp reserves the right, at our sole discretion, to modify or replace these Terms at any time. By continuing to access or use our App after those revisions become effective, you agree to be bound by the revised terms.</p>

                                        <h4>9. Contact Information</h4>
                                        <p>If you have any questions about these Terms, please contact us at customerservice@zonezapp.com.</p>

                                        <p style={{ marginTop: '20px' }}>User Acknowledgment: By creating an account and using ZoneZapp, I acknowledge that I have read, understood, and agreed to the above Terms and Conditions.</p>

                                        <br/>

                                        <a className="back-link" href="#" onClick={handleToggleContent}>Go Back to Register</a>
                                    </div>
                                </div>
                            ) : (
                                <div className="container col-xxl-8 px-1 py-3">
                                    <div className="row flex-lg-row-reverse align-items-center g-5 py-5">
                                        <div className="col-10 col-sm-8 col-lg-6 model-cover">
                                            <img src={ModelCover} className="d-block mx-lg-auto img-fluid" alt="ZoneZapp Mockups" width="900" height="700" loading="lazy"></img>
                                        </div>
                                        <div className="col-lg-6">
                                            <GetInTouchForm handleToggleContent={handleToggleContent} />
                                        </div>
                                    </div>
                                </div>
                            )}






                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default DialogTrigger;
