import React from 'react';
import './Features.css';
import Restore from '@mui/icons-material/Restore';
import Handshake from '@mui/icons-material/Handshake';
import Diversity3 from '@mui/icons-material/Diversity3';



const Features = () => (
    <section id="features" className='features-wrapper'>
        <div className="container px-4 py-5" id="hanging-icons">
            <h2 className="features-heading text-center pb-2">Features</h2>

            <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
                <div className="col d-flex align-items-start animate__animated animate__fadeInTopLeft">
                    <div className="icon-square glassmorphism flex-shrink-0 me-3">
                        <Handshake className="bi" width="1.50em" height="1.50em" />
                    </div>
                    <div>
                        <h2 className='text-headings'>Community Engagement</h2>
                        <p className='txt-justify'>
                            Engage with your local community through discussions and sharing.
                        </p>
                    </div>
                </div>
                <div className="col d-flex align-items-start animate__animated animate__fadeIn ">
                    <div className="icon-square glassmorphism flex-shrink-0 me-3">
                        <Restore className="bi" width="1.50em" height="1.50em" />
                    </div>
                    <div>
                        <h2 className='text-headings'>Real-Time Updates</h2>
                        <p className='txt-justify'>
                            Do not depend on biased news, get reality from users on the ground.
                            Get instant notifications about events and news in your area.
                        </p>
                    </div>
                </div>
                <div className="col d-flex align-items-start animate__animated animate__fadeInTopRight">
                    <div className="icon-square glassmorphism flex-shrink-0 me-3">
                        <Diversity3 className="bi" width="1.50em" height="1.50em" />
                    </div>
                    <div>
                        <h2 className='text-headings'>Enterprise Accounts</h2>
                        <p className='txt-justify'>
                            Businesses can set up official accounts to promote their products.
                            Enterprise users can share information within a 10 km radius and organize groups or categories.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section >
);

export default Features;
