import React, { useState } from 'react';
import './Subscribe.css';

const Subscribe = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (email) {
            try {
                // Send POST request to the provided URL
                const response = await fetch('https://alpha.zonezapp.com/api/users', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: email,
                        origin: 'Newsletter',
                    }),
                });

                // Check if the request was successful
                if (response.ok) {
                    const data = await response.json();
                    setEmail('');
                    setMessage(`${email}, thank you for subscribing!`);

                    // Reload the page and navigate to the #subscription section
                    window.location.href = '#subscription'; // Scrolls to the section with id="subscription"
                    window.location.reload(); // Reload the page
                } else {
                    setMessage('Something went wrong. Please try again.');
                }
            } catch (error) {
                // console.error('Error during submission:', error);
                setMessage('There was an error. Please try again later.');
            }
        } else {
            setMessage('Please enter a valid email.');
        }
    };

    return (
        <section id="subscription" className="subscribe subscribe-wrapper">
            <div className="color-overlay"></div>
            <div className="container">
                <div className="row row-div">
                    <div className="col-md-6 ml-md-auto subscribe-form">
                        <div className="st-subscribe-title text-center">
                            <h3>Subscribe to our newsletter</h3>
                            <p className='text-center'>Don’t miss this chance! No spam promise - only our latest news and freebies!</p>
                        </div>
                        <form id="subscribe" onSubmit={handleSubmit}>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                name="subscribe-email"
                                id="st-email"
                                placeholder="Enter your e-mail"
                                required
                            />
                            <input type="submit" name="subscribe-submit" value="Subscribe" />
                            <label htmlFor="st-email" className="st-subscribe-message">{message}</label>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Subscribe;